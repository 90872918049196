<template>
  <div class="px-5" :key="componentKey">
    <v-row>
      <v-col>
        <span class="text-h4 mb-5 primary--text">
          {{ isUpdate ? 'แก้ไข' : 'เพิ่ม' }}{{ docType }}
        </span>
        <span class="primary--text ml-10">
          <v-icon class="mb-2" color="primary">mdi-file</v-icon>
          {{ form.number }}
        </span>
      </v-col>
      <v-col class="text-right">
        <v-btn class="ml-5" small color="grey" outlined @click="close()">
          ยกเลิก
        </v-btn>
        <!-- <v-btn class="ml-5" small color="primary" @click="close()">บันทึกข้อมูล</v-btn> -->
        <v-btn
          class="ml-5"
          color="primary"
          small
          @click="save()"
          :disabled="paid"
          >บันทึกข้อมูล</v-btn
        >
        <v-btn
          class="ml-5"
          small
          color="error"
          :disabled="!isUpdate || paid"
          @click="dialogPay = true"
        >
          จ่ายเงิน
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <span class="text-h4 dark--text mr-5">รวมจำนวนเงินทั้งสิ้น</span>
        <span class="text-h2 primary--text">
        {{ form.net_price | numeral('0,0.00') }}
        </span>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="project_name"
          v-model="form.project_name"
          label="ชื่อโปรเจ็ค"
          outlined
          dense
          :error-messages="$v.form.project_name.$error ? $error_text : ''"
        />
        <v-text-field
          name=""
          label="ชื่อพนักงาน"
          v-model="form.approval"
          :error-messages="$v.form.approval.$error ? $error_text : ''"
          outlined
          dense
          disabled
        />
        <v-autocomplete
          v-model="dealers"
          :items="list.filter_dealer"
          :search-input.sync="search_dealer"
          return-object
          item-text="name"
          item-value="_id"
          outlined
          dense
          label="ชื่อผู้จำหน่าย"
          autocomplete="off"
          @change="
            val => {
              getDealerById(val._id);
            }
          "
          auto-select-first
          :disabled="paid"
          :error-messages="$v.form.dealer_name.$error ? $error_text : ''"
        >
          <template slot="item" slot-scope="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
        <v-textarea
          name="location"
          label="ที่อยู่"
          rows="1"
          outlined
          dense
          autocomplete="off"
          @click="
            (dialogAddressData = form.location),
              (dialogAddress = true),
              (dialogAddressKey = 'location')
          "
          :value="
            form.location.address
              ? `${form.location.address} ${form.location.sub_district} ${form.location.district} ${form.location.province} ${form.location.postal_code}`
              : ''
          "
          :disabled="paid"
        ></v-textarea>
        <v-text-field
          name="taxpayer_number"
          v-model="form.taxpayer_number"
          label="เลขประจำตัวผู้เสียภาษี"
          outlined
          dense
          :disabled="paid"
        />

        <v-text-field
          name="branch_office_number"
          v-model="form.branch_office_number"
          label="สำนักงานสาขา/เลขที่"
          outlined
          dense
          hide-details
          :disabled="paid"
        />
      </v-col>
      <v-col cols="4" offset="5">
        <v-row>
          <v-col cols="5">
            <v-row class="border-white">
              <v-col cols="12" class="py-5">วันที่ออกใบ</v-col>
              <v-col cols="12" class="py-5">วันครบกำหนด</v-col>
              <v-col cols="12">ราคาสินค้า</v-col>
              <v-col cols="12">ลงรายจ่ายใน</v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row class="border-grey">
              <v-col cols="12">
                <DatePicker
                  v-model="form.date_of_issue"
                  @onChange="val => (form.date_of_issue = val)"
                  hideDetails
                  :disabled="paid"
                />
              </v-col>
              <v-col cols="12">
                <DatePicker
                  v-model="form.due_date"
                  @onChange="val => (form.due_date = val)"
                  hideDetails
                  :disabled="paid"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  class="mt-0 py-0"
                  row
                  hide-details
                  v-model="form.vat_registration"
                  :disabled="paid"
                >
                  <v-radio label="ไม่รวมภาษี" :value="false"></v-radio>
                  <v-radio label="รวมภาษี" :value="true"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-show="list.expense_branch.length > 0"
                  hide-details
                  outlined
                  dense
                  :disabled="paid"
                  :items="list.expense_branch"
                  v-model="form.expense_branch"
                  item-value="value"
                  item-text="name"
                  label="กรุณาเลือก"
                  :error-messages="
                    $v.form.expense_branch.$error ? $error_text : ''
                  "
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12">
        <v-card elevation="1" class="mt-5">
          <v-simple-table>
            <template v-slot:default>
              <thead class="light">
                <tr>
                  <th class="text-center" style="width:5px;">#</th>
                  <th class="text-center">รายละเอียด</th>
                  <th class="text-center" style="width:150px;">หมวดหมู่</th>
                  <th class="text-center" style="width:16%;">หมวดหมู่ย่อย</th>
                  <th class="text-center" style="width:120px;">ราคาต่อหน่วย</th>
                  <th class="text-center" style="width:50px;">จำนวน</th>
                  <th class="text-center" style="width:50px;">ส่วนลด(บาท)</th>
                  <th class="text-center" style="width:50px;">ภาษี</th>
                  <th class="text-center" style="width:120px;">ราคารวม</th>
                  <th class="text-center" style="width:40px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in form.product" :key="index">
                  <td class="text-center">{{ index + 1 }}.</td>
                  <td>
                    <v-text-field
                      class="mt-6"
                      outlined
                      dense
                      v-model="item.detail"
                      :error-messages="
                        $v.form.product.$each[index].detail.$error
                          ? $error_text
                          : ''
                      "
                      :disabled="paid"
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      :items="list.category_id"
                      v-model="item.category_id"
                      item-value="value"
                      item-text="text"
                      outlined
                      dense
                      solo
                      filled
                      autocomplete="off"
                      auto-select-first
                      hide-details
                      :error-messages="
                        $v.form.product.$each[index].category_id.$error
                          ? $error_text
                          : ''
                      "
                      @change="(val)=>{getSubCategory(val)}"
                      :disabled="paid"
                    >
                    </v-autocomplete>
                  </td>

                  <td>
                    <v-autocomplete
                      :items="list.sub_category_id[item.category_id]"
                      :disabled="!item.category_id || paid || loading"
                      :error-messages="
                        $v.form.product.$each[index].sub_category_id.$error
                          ? $error_text
                          : ''
                      "
                      v-model="item.sub_category_id"
                      item-value="_id"
                      item-text="name"
                      outlined
                      dense
                      solo
                      filled
                      autocomplete="off"
                      auto-select-first
                      hide-details
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-6"
                      outlined
                      dense
                      :disabled="paid"
                      :error-messages="
                        $v.form.product.$each[index].price_per_unit.$error
                          ? $error_text
                          : ''
                      "
                      v-model.number="item.price_per_unit"
                      @change="
                        value =>
                          handleProductPrice(index, 'price_per_unit', value)
                      "
                      @focus="$event.target.select()"
                    />
                  </td>
                  <td>
                    <v-text-field
                      class="mt-6"
                      outlined
                      dense
                      :error-messages="
                        $v.form.product.$each[index].amount.$error
                          ? $error_text
                          : ''
                      "
                      v-model.number="item.amount"
                      :disabled="paid"
                      @change="
                        value => handleProductPrice(index, 'amount', value)
                      "
                      @focus="$event.target.select()"
                    />
                  </td>
                  <td>
                    <v-text-field
                      class="mt-6"
                      outlined
                      dense
                      v-model.number="item.discount"
                      :error-messages="
                        $v.form.product.$each[index].discount.$error
                          ? $error_text
                          : ''
                      "
                      :disabled="paid"
                      @change="
                        value => handleProductPrice(index, 'discount', value)
                      "
                        @focus="$event.target.select()"
                    />
                  </td>
                  <td>
                    <v-select
                      class="mt-6"
                      outlined
                      dense
                      v-model.number="item.vat"
                      :items="list.vat_dict"
                      item-value="value"
                      item-text="name"
                      :disabled="paid"
                      :error-messages="
                        $v.form.product.$each[index].vat.$error
                          ? $error_text
                          : ''
                      "
                      @change="value => handleProductPrice(index, 'vat', value)"
                    >
                    </v-select>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-6"
                      outlined
                      dense
                      v-model.number="item.total_price"
                      :error-messages="
                        $v.form.product.$each[index].total_price.$error
                          ? $error_text
                          : ''
                      "
                      :disabled="true"
                      @change="
                        value => handleProductPrice(index, 'total_price', value)
                      "
                    />
                  </td>
                  <td class="text-center px1">
                    <v-btn
                      v-if="index > 0"
                      class="my-1"
                      color="error"
                      fab
                      tile
                      small
                      elevation="0"
                      @click="deleteProduct(index)"
                      :disabled="paid"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="4">
            <v-btn
              class="mb-4"
              color="primary"
              outlined
              @click="addProduct"
              :disabled="paid"
              >+ เพิ่มสินค้า</v-btn
            >
            <v-textarea
              label="หมายเหตุ"
              outlined
              dense
              v-model="form.note"
              :disabled="paid"
            />
          </v-col>
          <v-col cols="6" md="5" lg="4" xl="3" class="pr-7 fz-3">
            <div class="mb-3">
              <span>รวมเป็นเงิน</span>
              <span class="float-right">{{
                total_price | numeral('0,0.00')
              }}</span>
            </div>
            <div class="mb-3">
              <span>ส่วนลดรวม</span>
              <span class="float-right">{{
                total_discount | numeral('0,0.00')
              }}</span>
            </div>
            <div class="mb-3">
              <span>ราคาหลังหักส่วนลด</span>
              <span class="float-right">{{
                (total_price - total_discount) | numeral('0,0.00')
              }}</span>
            </div>
            <div class="mb-3">
              <span>ภาษีมูลค่าเพิ่ม 7%</span>
              <span class="float-right">{{
                vat_price | numeral('0,0.00')
              }}</span>
            </div>
            <div class="mb-3">
              <span>จำนวนเงินรวมทั้งสิ้น</span>
              <span class="float-right">{{
                net_price | numeral('0,0.00')
              }}</span>
            </div>
          </v-col>
        </v-row>
        <span v-if="paid">
          <div class="green--text fz-7 mt-10">บันทึกการชำระเงิน</div>
          <v-card elevation="1" class="mt-5">
            <v-simple-table>
              <template v-slot:default>
                <thead class="green">
                  <tr>
                    <th class="text-center white--text">วันที่ชำระ</th>
                    <th class="text-center white--text">ช่องทาง</th>
                    <th class="text-center white--text">ธนาคาร</th>
                    <th class="text-center white--text">รายละเอียด</th>
                    <th class="text-center white--text">ยอดรับชำระ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="paid">
                    <td class="text-center">
                      {{ form.payment.date | moment($formatDate) }}
                    </td>
                    <td class="text-center">
                      {{ form.payment.type == 1 ? 'เงินสด' : '' }}
                      {{ form.payment.type == 2 ? 'โอน' : '' }}
                      {{ form.payment.type == 3 ? 'บัตรเครดิต' : '' }}
                      {{ form.payment.type == 4 ? 'อื่นๆ' : '' }}
                    </td>
                    <td class="text-center">{{ form.payment.reference }}</td>
                    <td class="text-center">{{ form.payment.note }}</td>
                    <td class="text-center">
                      {{ form.payment.amount | numeral('0,0.00') }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn class="ml-5" small color="grey" outlined @click="close()">
          ยกเลิก
        </v-btn>
        <!-- <v-btn class="ml-5" small color="primary" @click="close()">บันทึกข้อมูล</v-btn> -->
        <v-btn
          class="ml-5"
          color="primary"
          small
          @click="save()"
          :disabled="paid"
          >บันทึกข้อมูล</v-btn
        >
        <v-btn
          class="ml-5"
          small
          color="error"
          :disabled="!isUpdate || paid"
          @click="dialogPay = true"
        >
          จ่ายเงิน
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="600" v-model="dialogPay">
      <Pay
        @onClose="closePayDialog"
        :amount="form.net_price"
        @successPay="successPay"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddressDetail from '@/components/Address/AddressDetail.vue';
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import DatePicker from '@/components/DatePicker.vue';
import Pay from './Pay.vue';
import {
  required,
  maxLength,
  minLength,
  numeric,
  decimal,
  minValue
} from 'vuelidate/lib/validators';
import * as moment from 'moment';
import { mapState } from 'vuex';

export default {
  components: {
    AddressDetail,
    PrintDocument,
    Pay,
    DatePicker
  },
  validations: {
    form: {
      project_name: { required },
      approval: { required },
      //branch_office_number: { required },
      date_of_issue: { required },
      due_date: { required },
      dealer_name: { required },
      expense_branch: { required },

      // location: { required, $each: {
      //   required
      // }},
      // note: "",
      product: {
        required,
        $each: {
          amount: {
            required,
            decimal
          },
          category_id: {
            required
          },
          detail: {
            required
          },
          discount: {
            required,
            decimal
          },
          price: {
            required,
            decimal
          },
          total_price: {
            required,
            decimal
          },
          price_per_unit: {
            required,
            decimal
          },
          sub_category_id: {
            required
          },
          vat: {
            required
          },
          vat_price: {
            required
          }
        }
      }
      //taxpayer_number: { required, numeric },
    }
  },
  data: () => ({
    docType: 'ค่าใช้จ่าย',
    docPath: 'expenditure',
    dealers:  {
      _id: '',
      name: '',
    },
    search_dealer: '',
    dialogPay: false,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: ''
    },
    menu: {
      due_date: false
    },
    form: {
      _id: '',
      approval: '',
      branch_office_number: '',
      number: '',
      date_of_issue: moment().format('YYYY-MM-DDTHH:mm:ss'),
      due_date: moment()
        .add(1, 'month')
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      location: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: ''
      },
      payment: {
        amount: '',
        date: '',
        note: '',
        reference: '',
        type: ''
      },
      net_price: 0,
      note: '',
      product: [
        {
          amount: 1,
          category_id: '',
          detail: '',
          discount: 0,
          price: 0,
          price_per_unit: 0,
          sub_category_id: '',
          sub_category_name: '',
          vat: 0,
          vat_price: 0,
          total_price: 0
        }
      ],
      expense_branch: null,
      dealer_id: '',
      dealer_name: '',
      project_name: '',
      status: 0,
      taxpayer_number: '',
      total_discount: 0,
      total_price: 0,
      vat_price: 0,
      vat_registration: false
    },
    list: {
      filter_dealer: [],
      product: [],
      dealer_name: [],
      expense_branch: [
        { value: 0, name: 'ชื่อออกใบเสร็จบริษัท 1' },
        { value: 1, name: 'ชื่อออกใบเสร็จบริษัท 2' }
      ],
      sub_category_id: [],
      category_id: [
        { text: 'กรุณาเลือกหมวดหมู่', value: '' },
        { text: 'พรบ.', value: 'PC1' },
        { text: 'ประกันภัย', value: 'PC2' },
        { text: 'ขนส่ง', value: 'PC3' },
        { text: 'ทั่วไป', value: 'PC4' },
        { text: 'ค่าใช้จ่ายบริษัท', value: 'PC5' }
      ],
      detail: [],
      vat_dict: [
        { value: 0, name: 'ยกเว้น' },
        { value: 1, name: '7%' },
        { value: 2, name: '0%' }
      ]
    },
    payment_type: [
      { text: 'เงินสด', value: 1 },
      { text: 'โอน', value: 2 },
      { text: 'บัตรเครดิต', value: 3 },
      { text: 'อื่นๆ', value: 4 }
    ],
    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    componentKey: 0,
    temp_dealer: { _id: '', name: '' },
    form_dealer: {
      dealer_id: 0,
      name: '',
      contact_people: '',
      contact_tel: '',
      taxpayer_number: '',
      branch_office: '',
      location: {
        address: '',
        building: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: ''
      },
      note: ''
    }
  }),

  async created() {
    this.loading = false;

    await this.getDealer();
    await this.getSubCategory('PC1');
    await this.getSubCategory('PC2');
    await this.getSubCategory('PC3');
    await this.getSubCategory('PC4');
    await this.getSubCategory('PC5');
    await this.getExpenseNumber();
    if (this.isUpdate) await this.getData();
    this.form.branch_id = this.$store.state.selected_branch._id;
    this.form.approval = this.$store.state.profile.name;
    this.list.expense_branch = [];
    let selectBranch = this.$store.state.list.branchs.filter(
      branch => branch._id == this.$store.state.selected_branch._id
    );

    if (selectBranch.length > 0) {
      if (selectBranch[0].is_split_bill) {
        this.list.expense_branch = [
          { name: selectBranch[0].name_for_receipt, value: 0 },
          { name: selectBranch[0].split_bill_data.name, value: 1 }
        ];
      } else {
        this.list.expense_branch = [
          { name: selectBranch[0].name_for_receipt, value: 0 }
        ];
      }
    }

    if (this.$route.query.payment) {
      this.dialogPay = true;
    }
    if (this.list.expense_branch.length > 1) {
      this.form.expense_branch = null;
    } else {
      this.form.expense_branch = this.list.expense_branch[0];
    }
  },

  methods: {
    async getExpenseNumber() {
      let filter = {
        branch_id: this.$store.state.selected_branch._id,
        expense_type: 0
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/check_expense_number`, body)
        .then(res => {
          this.form.number = res.result;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    onSubmitAddress(val) {
      this.form.location = val;
      this.dialogAddress = false;
    },
    async getDealer(text = '') {
      let filter = {
        item_per_page: 1000,
        page: 1,
        start_with: text
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/dealer/get_dealer`, body)
        .then(res => {
          this.list.dealers = res.result.data;
          this.list.filter_dealer = res.result.data;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    async getData() {
      if (this.$route.params.id) {
        let body = {
          token: this.$jwt.sign(
            { expense_id: this.$route.params.id },
            this.$privateKey,
            { noTimestamp: true }
          )
        };
        await this.$axios
          .post(`${this.$baseUrl}/expense/get_by_id`, body)
          .then(async res => {
            this.form = { ...res.result };
            this.getDealerById(this.form.dealer_id);
            for (let index = 0; index < res.result.product.length; index++) {
              this.form.product[index].total_price =
                this.form.product[index].price_per_unit *
                  this.form.product[index].amount -
                this.form.product[index].discount;
            }
            this.handleDealer()
          })
          .catch(err => {
            this.$alertServerError({ title: err.error_message });
          });
      }
    },
    async getDealerById(dealer_id) {
      this.loading = true
      this.form.dealer_id = dealer_id;
      if (dealer_id == '') {
        this.dealers =  ''
        this.form.dealer_name = this.search_dealer;
        this.form.location = {
          address: '',
          building: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: ''
        }
        this.form.taxpayer_number = ''
        this.form.branch_office_number = ''
      }
      if (dealer_id) {
        let body = {
          token: this.$jwt.sign({ dealer_id: dealer_id }, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/dealer/get_by_id`, body)
          .then(async res => {
            let data = res.result;
            if(data){
              this.dealers = data;
              this.search_dealer = data.name;

              this.form.dealer_id = dealer_id;
              this.form.dealer_name = data.name;
              this.form.taxpayer_number = data.taxpayer_number;
              this.form.branch_office_number = data.branch_office;
              this.form.location = data.location;
              if (data.location == '') {
                this.form.location = {
                  address: '',
                  district: '',
                  sub_district: '',
                  province: '',
                  postal_code: ''
                };
              }
            }
          })
          .catch(err => {
            console.log('err', err);
          });
        this.loading = false
      }
    },
    handleDealer() {
      console.log('handleDealer')
      setTimeout(() => {
        this.temp_dealer.name = this.search_dealer || '';
        if (this.search_dealer != '') {
          this.form.dealer_name = this.search_dealer;
          this.list.filter_dealer = this.list.dealers.filter(e => {
            return (
              (e.name || '')
                .toLowerCase()
                .indexOf((this.search_dealer || '').toLowerCase()) > -1
            );
          });
          let data = this.list.filter_dealer.find(
            item => item.name === this.temp_dealer.name
          );
          if (!!data) {
            this.getDealerById(data._id);
          } else if(this.temp_dealer.name!='') {
            this.list.filter_dealer.unshift(this.temp_dealer);
            // this.getDealerById('');
          }
        } else {
          this.dealers =  {
            _id: '',
            name: '',
          },
          this.form.dealer_name = '';
        }
      }, 500);
    },
    handleProductPrice(index, key = 'discount', val) {
      var discount = this.form.product[index].discount;
      var vat = this.form.product[index].vat;
      var price = this.form.product[index].price;
      var price_per_unit = this.form.product[index].price_per_unit;
      var amount = this.form.product[index].amount;
      if (key !== 're_calculate') {
        if (key == 'discount')
          discount = val === '' || val === null ? 0 : parseFloat(val);
        else if (key == 'vat')
          vat = val === '' || val === null ? 0 : parseFloat(val);
        else if (key == 'price')
          price = val === '' || val === null ? 0 : parseFloat(val);
        else if (key == 'price_per_unit')
          price_per_unit = val === '' || val === null ? 0 : parseFloat(val);
        else if (key == 'amount')
          amount = val === '' || val === null ? 0 : parseFloat(val);
      }
      let product_price = amount * price_per_unit;
      let vat_price = 0;

      let total_price = product_price - discount;

      if (this.form.vat_registration) {
        if (vat == 1) vat_price = (total_price / 1.07) * 0.07;
        else vat_price = 0;
        price = total_price;
      } else {
        if (vat == 1) vat_price = ((product_price - discount) * 7) / 100;
        else vat_price = 0;
        price = total_price + vat_price;
      }
      this.form.product[index].price = parseFloat(parseFloat(price).toFixed(2));
      this.form.product[index].vat_price = parseFloat(
        parseFloat(vat_price).toFixed(2)
      );
      this.form.product[index].total_price = parseFloat(
        parseFloat(total_price).toFixed(2)
      );
      this.forceRerender();
    },
    addProduct() {
      this.form.product.push({
        amount: 1,
        category_id: '',
        detail: '',
        discount: 0,
        price: 0,
        price_per_unit: 0,
        sub_category_id: '',
        sub_category_name: '',
        vat: 0,
        vat_price: 0
      });
    },
    deleteProduct(index) {
      this.form.product.splice(index, 1);
    },

    async getSubCategory(category_id) {
      this.loading = true;
      let form = {
        category_id: category_id
      };
      let body = {
        token: this.$jwt.sign(form, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense_category/get_sub_category_list`, body)
        .then(async res => {
          this.list.sub_category_id[category_id] = res.result;
        });
      this.loading = false;
    },
    async saveDealer() {
      this.form_dealer.name = this.dealers.name;
      this.form_dealer.dealer_id = this.dealers._id;
      this.form_dealer.taxpayer_number = this.form.taxpayer_number;
      this.form_dealer.branch_office = this.form.branch_office_number;
      this.form_dealer.location = this.form.location;
      if (this.dealers._id == "" || this.form.dealer_id == '') {
        await this.$axios
          .post(`${this.$baseUrl}/dealer/add_dealer`, {
            token: this.$jwt.sign(this.form_dealer, this.$privateKey, {
              noTimestamp: true
            })
          })
          .then(res => {
            this.getDealer();
          })
          .catch(err => {
            console.log('err', err);
          });
      } else  {
        await this.$axios
          .post(`${this.$baseUrl}/dealer/edit_dealer`, {
            token: this.$jwt.sign(this.form_dealer, this.$privateKey, {
              noTimestamp: true
            })
          })
          .then(res => {
            this.getDealer();
          })
          .catch(err => {
            console.log('err', err);
          });
      }
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            this.saveDealer();
            let subPath = 'create_expense';
            let formWithOutTotalPrice = [];
            for (let product of this.form.product) {
              let { total_price, ...withoutTotal } = product;
              formWithOutTotalPrice.push(withoutTotal);
            }
            if (this.isUpdate) {
              subPath = 'edit_expense';
              this.form = {
                expense_id: this.form._id,
                ...this.form,
                product: formWithOutTotalPrice
              };
              // delete this.form._id;
            }
            delete this.form._id;
            let body = {
              token: this.$jwt.sign(
                { ...this.form, product: formWithOutTotalPrice },
                this.$privateKey,
                {
                  noTimestamp: true
                }
              )
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/${subPath}`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate)
                  this.$router.push({
                    name: `expenses-expenditure-update`,
                    params: { id: res.result }
                  });
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
          this.$v.form.$reset();
        }
      );
    },
    close() {
      this.$router.push({ name: `expenses-${this.docPath}` });
    },
    async saveClose() {
      await this.save();
      await this.close();
    },
    async onSubmitAddress(addressDetail) {
      this.form.location = addressDetail;
      this.dialogAddress = false;
    },
    closePayDialog() {
      this.dialogPay = false;
    },
    successPay() {
      this.closePayDialog();
      this.getData();
    },
    forceRerender() {
      this.componentKey += 1;
    }
  },
  watch: {
    search_dealer: function (val) {
      console.log(val)
      if (!this.loading && val!==null) {
        val !== this.dealers && this.handleDealer();
      }
    },
    $route() {
      this.$nextTick(this.getData);
    },
    'form.vat_registration': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        for (let i = 0; i < this.form.product.length; i++) {
          this.handleProductPrice(i, 're_calculate', null);
        }
      }
    }
  },
  computed: {
    isUpdate() {
      return this.$route.params.id ? true : false;
    },
    paid() {
      return this.form.status == 1 ? true : false;
    },
    total_price() {
      if (this.form.status != 0 && this.$route.params.id)
        return this.form.total_price;

      let price = 0;
      this.form.product.forEach(product => {
        price +=
          parseFloat(product.price_per_unit || 0) *
          parseFloat(product.amount || 0);
      });
      this.form.total_price = price;
      return price;
    },
    total_discount() {
      if (this.form.status != 0 && this.$route.params.id)
        return this.form.total_discount;

      let discount = 0;
      this.form.product.forEach(product => {
        discount += parseFloat(product.discount || 0);
      });
      this.form.total_discount = discount;
      return discount;
    },
    vat_price() {
      if (this.form.status != 0 && this.$route.params.id)
        return this.form.vat_price;
      let price = 0;
      this.form.product.forEach(product => {
        if (product.vat == 1) price += parseFloat(product.vat_price || 0);
      });
      this.form.vat_price = price;
      return price;
    },
    net_price() {
      if (this.form.status != 0 && this.$route.params.id)
        return this.form.net_price;
      let price = 0;
      this.form.product.forEach(product => {
        price += parseFloat(product.price || 0);
      });
      this.form.net_price = price;
      return parseFloat(price).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
thead.v-data-table-header {
  background: blue !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 6px;
}
</style>
